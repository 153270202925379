<template>
  <div>
    <h1>NFC Tag Reader</h1>

    <!-- Nachricht, wenn NFC nicht verfügbar ist -->
    <div v-if="!nfcAvailable" class="error">
      <p>NFC wird in diesem Browser nicht unterstützt.</p>

      <!-- Eingabefeld und Absenden-Button -->
      <div>
        <label for="serialNumber">Seriennummer manuell eingeben:</label>
        <input type="text" v-model="manualSerialNumber" id="serialNumber" placeholder="Seriennummer eingeben"/>
        <button @click="sendManualSerialToApi">Absenden</button>
      </div>
    </div>

    <!-- Nachricht während des NFC-Scans -->
    <div v-if="nfcScanning">
      <p>NFC-Scan läuft, halte einen NFC-Tag an das Gerät...</p>
    </div>

    <!-- Tag-Inhalt anzeigen, falls vorhanden -->
    <div v-if="nfcContent">
      <h2>Tag Content:</h2>
      <p>{{ nfcContent }}</p>
    </div>

    <!-- Rückmeldung von der API -->
    <div v-if="apiMessage" class="api-message">
      <p>{{ apiMessage }}</p>
    </div>
  </div>
</template>

<script>
/* global NDEFReader */
export default {
  data() {
    return {
      nfcContent: null,
      nfcAvailable: true,  // NFC-Verfügbarkeit
      nfcScanning: false,  // Ob der NFC-Scan läuft
      manualSerialNumber: '', // Manuell eingegebene Seriennummer
      apiMessage: null // Rückmeldung der API
    };
  },
  methods: {
    async startNfc() {
      try {
        if ('NDEFReader' in window) {
          this.nfcScanning = true; // Scan-Status anzeigen
          const ndef = new NDEFReader();

          await ndef.scan();

          ndef.onreading = async event => {
            const serialNumber = event.serialNumber || 'unknown';
            this.nfcContent = `Seriennummer: ${serialNumber}`;

            // Sende die Seriennummer per POST an die API
            await this.sendNfcSerialToApi(serialNumber);
          };
        } else {
          this.nfcAvailable = false;
        }
      } catch (error) {
        console.error('Error reading NFC tag:', error);
        this.nfcScanning = false;
      }
    },
    async sendNfcSerialToApi(serialNumber) {
      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/time-tracking`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({serialNumber: serialNumber})
        });

        if (!response.ok) {
          throw new Error('Fehler beim Senden der Seriennummer an die API');
        }

        const result = await response.json();
        this.showApiMessage('NFC-Seriennummer erfolgreich gesendet: ' + result.message);
      } catch (error) {
        this.showApiMessage('Fehler bei der API-Anfrage: ' + error.message);
      } finally {
        this.nfcScanning = false; // NFC-Scan beenden
      }
    },
    async sendManualSerialToApi() {
      if (!this.manualSerialNumber) {
        alert('Bitte eine Seriennummer eingeben!');
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/time-tracking`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({serialNumber: this.manualSerialNumber})
        });

        if (!response.ok) {
          throw new Error('Fehler beim Senden der Seriennummer an die API');
        }

        const result = await response.json();
        this.showApiMessage('Manuelle Seriennummer erfolgreich gesendet: ' + result.message);
        this.manualSerialNumber = ''; // Eingabefeld nach erfolgreichem Absenden leeren
      } catch (error) {
        this.showApiMessage('Fehler bei der API-Anfrage: ' + error.message);
      }
    },
    // API-Nachricht anzeigen und nach 5 Sekunden wieder ausblenden
    showApiMessage(message) {
      this.apiMessage = message;
      setTimeout(() => {
        this.apiMessage = null;
      }, 5000); // Nachricht nach 5 Sekunden ausblenden
    }
  },
  mounted() {
    // Überprüfen, ob NFC unterstützt wird
    if ('NDEFReader' in window) {
      this.startNfc(); // NFC-Scan automatisch starten
    } else {
      this.nfcAvailable = false; // NFC nicht verfügbar
    }
  }
};
</script>

<style scoped>
h1, h2 {
  text-align: center;
}

button {
  display: block;
  margin: 10px auto;
}

input {
  display: block;
  margin: 0 auto;
  padding: 5px;
  width: 200px;
}

.error {
  color: red;
  text-align: center;
}

p {
  text-align: center;
}

.api-message {
  text-align: center;
  background-color: lightgreen;
  padding: 10px;
  margin: 10px auto;
  width: fit-content;
  border-radius: 5px;
  color: #333;
}
</style>

<template>
  <div id="app">
    <NfcReader />
  </div>
</template>

<script>
// import NfcReader from './components/NfcReader.vue';
import NfcReader from '../components/NfcReader.vue';
export default {
  name: 'App',
  components: {
    NfcReader
  }
};
</script>

<style>
/* Dein CSS-Styling */
</style>

import {createApp} from 'vue';
import App from './App.vue';
import {createPinia} from 'pinia';
import router from './router'

import "bootstrap/dist/css/bootstrap.css"
import piniaLocalStoragePlugin from './plugins/piniaLocalStorage';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaLocalStoragePlugin);
app.use(pinia);
createApp(App).use(router).mount('#app')


